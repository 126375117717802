<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("users.sp.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">
                {{ $t("users.sp.title") }}
              </h6>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('users.sp.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-2">
                  <b-tab title="Region" active v-if="headOffice.includes(role)">
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="rg in dataRegion"
                        :key="rg.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :value="parseInt(rg.id)"
                          :id="rg.id"
                          v-model="filterRegion"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="rg.id"
                        >
                          {{ rg.region }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterRegion == [] }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Nama TSS">
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"
                        v-model="searchTSS"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="tss in dataTSSS"
                        :key="tss.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :value="tss.id"
                          :id="tss.id"
                          v-model="filterTSS"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="tss.id"
                        >
                          {{ tss.name }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterTSS == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div>
          </div>
          <div class="table-borderless mx-3">
            <b-table-simple hover responsive>
              <b-thead class="bv-head">
                <b-tr>
                  <b-th
                    ><input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                  /></b-th>
                  <b-th v-for="hd in $t('users.sp.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="loading">
                <table-spinner cs="8" />
              </b-tbody>
              <b-tbody v-else-if="this.dataSP.length !== 0">
                <b-tr class="ssp-16-400" v-for="data in dataSP" :key="data.id">
                  <b-td
                    ><input
                      type="checkbox"
                      :value="data.id"
                      v-model="spIds"
                      @click="select"
                  /></b-td>
                  <b-td>{{ data.name }}</b-td>
                  <b-td>{{ data.ugdn ? data.ugdn : "-" }}</b-td>
                  <b-td>{{ data.phone }}</b-td>
                  <b-td>{{ data.email }}</b-td>
                  <b-td>
                    <span v-if="data.detail[0].regions">
                      {{ data.detail[0].regions.region }}
                    </span>
                  </b-td>
                  <!-- <b-td>
                    {{ data.detail[0].tss ? data.detail[0].tss.name : "-" }}
                  </b-td> -->
                  <b-td v-if="data.isActive">
                    <span class="badge badge-blue">
                      ACTIVE
                    </span></b-td
                  >
                  <b-td v-else
                    ><span class="badge badge-red">
                      INACTIVE
                    </span></b-td
                  >
                  <b-td class="action-column">
                    <div class="action-wrapper font-12">
                      <i
                        class="ri-eye-fill ri-lg ri-mid action-btn"
                        @click="onDetail(data)"
                      ></i>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <b-tr>
                  <b-td colspan="9">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-sp" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <form>
          <div class="form-group">
            <label class="ssp-16 text-black">{{ $t("users.label.tss") }}</label>
            <input
              class="form-control"
              type="text"
              v-model="formDetail.detail[0].tss"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black">{{ $t("users.label.sp") }}</label>
            <input
              class="form-control"
              type="text"
              v-model="formDetail.name"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black">UGDN</label>
            <input
              class="form-control"
              type="text"
              v-model="formDetail.ugdn"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black">{{
              $t("users.label.phone")
            }}</label>
            <input
              class="form-control"
              type="text"
              v-model="formDetail.phone"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black">{{
              $t("users.label.email")
            }}</label>
            <input
              class="form-control"
              type="text"
              v-model="formDetail.email"
              :disabled="isDisabled"
            />
          </div>
          <BaseInput :blabel="$t('tssreq.label.wk')">
            <section v-for="wk in formDetail.detail" :key="wk.id">
              <table class="table table-borderless table-wk">
                <tbody>
                  <tr>
                    <td>{{ $t("tssreq.label.group") }}</td>
                    <td>:</td>
                    <td>{{ wk.group }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.country") }}</td>
                    <td>:</td>
                    <td>
                      {{ wk.country }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.region") }}</td>
                    <td>:</td>
                    <td>{{ wk.region }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.prov") }}</td>
                    <td>:</td>
                    <td>
                      {{ wk.province }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.kab") }}</td>
                    <td>:</td>
                    <td>
                      {{ wk.kabupaten }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </BaseInput>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { Card, BaseInput } from "@/components/index";
import JwtService from "@/core/services/jwt.service";
import { RoleType } from "@/graphql/enum.type.js";
import { Q_LIST_USER } from "@/graphql/sa";
import { Q_LIST_USERSEARCH, Q_LIST_REGION } from "@/graphql/queries";
import Axios from "axios";

export default {
  components: {
    Card,
    BaseInput
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      dataSP: [],
      dataTSS: [],
      dataTSSS: [],
      dataRegion: [],
      dataFilterSP: [],
      dataProvinsiSP: [],
      dataKabupatenSP: [],
      optionRole: [RoleType.TSS, RoleType.SP],

      headOffice: [
        RoleType.CM,
        RoleType.NSM,
        RoleType.NMM,
        RoleType.SA,
        RoleType.MT
      ],
      role: JwtService.getRole(),
      detailUser: JwtService.getDetail(),
      countryID: [],
      regionID: [],
      tssIDS: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      searchTSS: "",
      filterInput: "",
      filterRegion: [],
      filterTSS: [],

      tssID: JwtService.getID(),

      modalTitle: null,
      isDisabled: null,
      isDetail: null,

      formDetail: {
        nama: null,
        nip: null,
        hp: null,
        email: null,
        tss: null,
        country: null,
        region: null,
        prov: null,
        kab: null
      },

      selected: [],
      allSelected: false,
      spIds: [],
      isEdit: false
    };
  },
  apollo: {
    listUser: {
      query: () => Q_LIST_USER,
      variables() {
        return {
          page: parseInt(this.currPage),
          limit: this.perPage,
          keyword: this.filterInput,
          role: this.optionRole[1],
          country_id: this.getCountryID(),
          region_id:
            this.filterRegion.length === 0
              ? this.getRegionID()
              : this.filterRegion,
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: this.filterTSS
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataSP = data.listUser.users;
        this.totalPages = data.listUser.totalPage;
        this.totalData = data.listUser.total;
      },
      error(err) {
        this.catchError(err);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    },
    listTSS: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: this.searchTSS,
          role: this.optionRole[0],
          country_id: [],
          region_id:
            this.filterRegion.length === 0
              ? this.getRegionID()
              : this.filterRegion,
          prov_id: this.getProvinceID(),
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      update: data => data.listUser,
      result({ data }) {
        this.dataTSSS = data.listUser.users;
      }
    },
    listRegion: {
      query: () => Q_LIST_REGION,
      result({ data }) {
        const r = this.getRegionID();
        if (r.length > 0) {
          this.dataRegion = data.listRegion.regions.filter(reg => {
            if (r.includes(parseInt(reg.id))) {
              return reg;
            }
          });
        } else {
          this.dataRegion = data.listRegion.regions;
        }
      }
    }
  },
  methods: {
    resetFilter() {
      this.filterRegion = [];
      this.filterTSS = [];
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    resetForm() {
      this.formDetail = {};
    },
    onDetail(data) {
      const details = [...data.detail];
      const kabGrouped = _(details)
        .groupBy("province_id")
        .map(function(kabs, key) {
          return {
            province_id: key,
            kabupatens: kabs.map(a => {
              return a.kabupaten;
            })
          };
        })
        .value();

      const detail = [];
      details.map(a => {
        const ob = {};
        if (a.countrys) {
          ob.countrys = a.countrys;
        }
        if (a.regions) {
          ob.regions = a.regions;
        }
        if (a.province) {
          ob.province = a.province;
        }
        if (a.kabupaten) {
          ob.kabupaten = [];
        }
        if (a.groups) {
          ob.groups = a.groups;
        }
        if (a.tss) {
          ob.tss = a.tss;
        }
        detail.push(ob);
      });

      const rst = [];
      detail.map(a => {
        kabGrouped.map(b => {
          if (b.province_id === a.province.id) {
            rst.push({
              country: a.countrys ? a.countrys.country : "-",
              region: a.regions ? a.regions.region : "-",
              province: a.province ? a.province.province : "-",
              kabupaten: b.kabupatens.map(obj => obj.kabupaten).join(", "),
              group: a.groups ? a.groups.group : "-",
              tss: a.tss ? a.tss.name : "-"
            });
          }
        });
      });

      this.modalTitle = this.$t("users.sp.detail");
      this.isDisabled = true;
      this.isDetail = true;
      this.$bvModal.show("modal-sp");
      this.resetForm();

      this.formDetail = Object.assign({}, data);
      this.formDetail.detail = _.uniqBy(rst, "province");
    },
    selectAll() {
      this.spIds = [];
      if (!this.allSelected) {
        for (let i in this.dataSP) {
          this.spIds.push(this.dataSP[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    // async     async handleExport() {
    //   try {
    //     const result = await this.$apollo.query({
    //       query: Q_LIST_USER,
    //       variables: {
    //         page: 1,
    //         limit: this.totalData,
    //         keyword: this.filterInput,
    //         role: this.optionRole[1],
    //         country_id: this.getCountryID(),
    //         region_id:
    //           this.filterRegion.length === 0
    //             ? this.getRegionID()
    //             : this.filterRegion,
    //         prov_id: [],
    //         kab_id: [],
    //         kec_id: [],
    //         kel_id: [],
    //         tss_id: []
    //       }
    //     });
    //     const list = result.data.listUser.users;
    //     import("@/plugins/export-excel").then(excel => {
    //       const tHeader = ["Name", "UGDN", "Email", "Phone", "Status", "TSS"];
    //       const filterVal = ["name", "ugdn", "email", "phone", "status", "tss"];
    //       setTimeout(() => {
    //         list.map(a => {
    //           if (a.isActive) {
    //             a.status = "Active";
    //           } else {
    //             a.status = "Inactive";
    //           }
    //           if (a.detail[0].tss) {
    //             a.tss = a.detail[0].tss.name;
    //           }
    //           return a;
    //         });
    //         const data = this.formatJson(filterVal, list);
    //         excel.export_json_to_excel({
    //           header: tHeader,
    //           data,
    //           filename: this.$route.name,
    //           autoWidth: true,
    //           bookType: "xlsx"
    //         });
    //       }, 1500);
    //       this.limit = 10;
    //     });
    //   } catch (e) {
    //     this.errorAlert(e);
    //   }
    // },
    async handleExport() {
      this.isLoading = true;
      await Axios.get(process.env.VUE_APP_URL_GRAPHQL + "/user/sp/excel", {
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + JwtService.getToken(),
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `salespromotor-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {}
};
</script>

<style scoped></style>
